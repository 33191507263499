<template>
  <b-card>
    <b-card-body>
        <div class="d-flex justify-content-between  flex-wrap">
            <b-button-group>
                <router-link to='/campaign/create/step-1' tag="button" class="btn btn-outline-primary"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    
                    >
                    Buat Campaign
                </router-link>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    v-b-modal.modal-filter
                    >
                    Filter
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    :disabled="items == 0"
                    @click="exportExcel()"
                    >
                    Export Excel
                </b-button>
            </b-button-group>

            <!-- filter -->
            <b-form-group
            label="Filter"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
            >
            <b-input-group size="sm">
                <b-form-input
                id="filterInput"
                v-debounce:1s="debFilter"
                type="text"
                placeholder="Type to Search"
                v-model="filter"
                />
                <b-input-group-append>
                <b-button
                    @click="resetFilter()"
                >
                    Clear
                </b-button>
                </b-input-group-append>
            </b-input-group>
            </b-form-group>
        </div>
    </b-card-body>

    <b-table
      striped
      hover
      responsive
      show-empty
      class="position-relative"
      :fields="fields"
      :items="items"
    >
      <template #cell(avatar)="data">
        <b-avatar :src="data.value" />
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(action)="data">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              @click="gotoDetailPage(data.item.id)"
              >
              <feather-icon icon="EyeIcon" />
          </b-button>
          &nbsp;
          <b-button
              v-if="data.item.campaign_status == 'draft'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="btn-icon"
              @click="gotoEditPage(data.item.id)"
              >
              <feather-icon icon="EditIcon" />
          </b-button>
          &nbsp;
          <b-button v-if="data.item.campaign_status == 'draft'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="btn-icon"
              @click="showModal(data.item.id)"
              >
              <feather-icon icon="Trash2Icon" />
          </b-button>
      </template>
      <template #empty="scope">
        <h5 class="text-center">Data Tidak Ditemukan</h5>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label=""
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
      
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
          @change="changePage"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>

      <b-modal ref="my-modal-delete" ok-title="Ya, Hapus!" cancel-title="Batal" @ok="deleteCampaign()" title="Delete Campaign">
        <div class="d-block text-center">
          <p>Apakah Anda yakin akan menghapus data ini?</p>
        </div>
      </b-modal>
    </b-card-body>

    <!-- basic modal -->
    <b-modal
      id="modal-filter"
      ref="modal-filter"
      title="Filter Berdasarkan"
      ok-title="Filter"
      cancel-title="Reset"
      hide-footer
      size="lg"
      @esc="clearFilter()"
      @backdrop="clearFilter()"
      @headerclose="clearFilter()"
    >
      <b-row class="p-1">
        <b-col cols="12">
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Status"
                  label-for="h-status"
                  label-cols-md="4"
                >
                  <b-form-select
                    v-model="selectedStatus"
                    :options="optionsStatus"></b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Tanggal Posting Awal"
                  label-for="h-email"
                  label-cols-md="4"
                >
                  <b-form-datepicker
                    id="firstPosting-datepicker"
                    name="firstPosting-datepicker"
                    v-model="firstPosting"
                    class="mb-1"
                    />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Tanggal Posting Akhir"
                  label-for="h-email"
                  label-cols-md="4"
                >
                  <b-form-datepicker
                    id="lastPosting-datepicker"
                    name="lastPosting-datepicker"
                    v-model="lastPosting"
                    class="mb-1"
                    />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Tanggal Deadline Konten"
                  label-for="h-email"
                  label-cols-md="4"
                >
                  <b-form-datepicker
                    id="contentDeadline-datepicker"
                    name="contentDeadline-datepicker"
                    v-model="contentDeadline"
                    class="mb-1"
                    />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1 ml-1 float-right"
                  @click="filterCampaign()"
                >
                  Filter
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  class="float-right"
                  @click="resetFilter()"
                >
                  Reset
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCard, BCardBody, BButtonGroup, BCardText, BRow, BCol, BForm, BFormDatepicker
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButtonGroup,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BFormDatepicker
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {key: 'no', label: 'No'},
        {key: 'order_id', label: 'Order ID'},
        {key: 'campaign_name', label: 'Nama Campaign'},
        {key: 'campaign_jenis', label: 'Jenis Campaign'},
        {key: 'campaign_status', label: 'Status Campaign'},
        {key: 'deadline_content', label: 'Deadline Konten'},
        {key: 'tgl_posting', label: 'Tanggal Posting'},
        {key: 'pengajuan', label: 'Pengajuan'},
        {key: 'influencer_terpilih', label: 'Influencer Terpilih'},
        {key: 'action', label: 'Aksi', thStyle: 'width:180px'}
      ],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
      selectedStatus: null,
      optionsStatus: [
        { value: null, text: 'Silahkan Pilih Status' },
        { value: 'draft', text: 'Draft' },
        { value: 'review', text: 'Review' },
        { value: 'on-progress', text: 'On Progress' },
        { value: 'content-review', text: 'Content Review' },
        { value: 'finished', text: 'Finished' },
        { value: 'on-broadcast', text: 'On Broadcast' }
      ],

      firstPosting: null,
      lastPosting: null,
      contentDeadline: null
    }
  },
  mounted() {
    // Set the initial number of items
    // this.totalRows = this.items.length
    this.getData()

    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      console.log('Modal is about to be shown', bvEvent, modalId)
      this.clearFilter()
    })
  },
  methods: {
    getData(name='', page=1) {
      let _ = this
      let filter = _.getFilter()
      axios.get('/advertiser/campaigns?page='+page+'&limit=10&sortBy=createdAt.desc&name='+ name + filter)
        .then(resp => {
          let _ = this
          console.log(resp.data.data)
          let data = resp.data.data.data.map((v,i) => {
            return {
                  id: v.id,
                  no: parseInt(i)+parseInt(1),
                  order_id: v.orderID,
                  campaign_name: v.name,
                  campaign_jenis: v.campaignType,
                  campaign_status: v.status,
                  deadline_content: v.contentDeadline,
                  tgl_posting: v.startDate +' s/d '+ v.endDate,
                  pengajuan: v.applicantCount,
                  influencer_terpilih: v.chosenInfluencer ? 1 : 0
              }
          })

          _.items = data
          _.totalRows = resp.data.data.totalData
          _.perPage = 10
          _.currentPage = resp.data.data.currentPage
        })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    gotoEditPage(data) {
      let _ = this
      _.$router.push('campaign/edit/'+data+'/step-1')
    },
    gotoDetailPage(data) {
      let _ = this
      _.$router.push({name: 'campaign-detail', params: {id: data}})
    },
    debFilter(val, e) {
      let _ = this
      _.getData(val)
    },
    resetFilter() {
      let _ = this
      
      _.filter = ''
      _.getData('')
    },
    exportExcel() {
      console.log('export')
      axios.get('/advertiser/campaigns/export', { responseType: 'blob' })
        .then(response => {
          const url = URL.createObjectURL(new Blob([response.data], {
              type: 'application/vnd.ms-excel'
          }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'campaign.xlsx')
          document.body.appendChild(link)
          link.click()
        })
    },
    getFilter() {
      let _ = this
      let filter = ''
      if ( _.selectedStatus ) {
        filter += '&status=' + _.selectedStatus
      }
      if ( _.firstPosting ) {
        let firstPosting = _.firstPosting.split('-')
        filter += '&startDate=' + firstPosting[2] + '-' + firstPosting[1] + '-' + firstPosting[0]
      }
      if ( _.lastPosting ) {
        let lastPosting = _.lastPosting.split('-')
        filter += '&endDate=' + lastPosting[2] + '-' + lastPosting[1] + '-' + lastPosting[0]
      }
      if ( _.contentDeadline ) {
        let contentDeadline = _.contentDeadline.split('-')
        filter += '&contentDeadline=' + contentDeadline[2] + '-' + contentDeadline[1] + '-' + contentDeadline[0]
      }

      return filter;
    },
    filterCampaign() {
      let _ = this
      _.getData()
      this.$refs['modal-filter'].hide()
    },
    resetFilter() {
      let _ = this

      _.selectedStatus = null
      _.firstPosting = null
      _.lastPosting = null
      _.contentDeadline = null
      _.getData()
      _.$refs['modal-filter'].hide()
    },
    changePage(page) {
      console.log(page)
      this.getData('', page)
    },
    showModal(campaignId) {
      let _ = this
      _.campaignIdDelete = campaignId
      this.$refs['my-modal-delete'].show()
    },
    deleteCampaign() {
      let _ = this
      axios.delete('/advertiser/campaigns/', { data: { id: _.campaignIdDelete } })
        .then(resp => {
          _.getData()
          _.$bvToast.toast("Data campaign berhasil dihapus", {
            title: "Sukses",
            variant: "success",
            solid: true,
          });
        })
        .catch(err => {
          console.log(err.response)
        })
    },
    clearFilter() {
      console.log('clearFilter')
      let _ = this
      _.selectedStatus = null
      _.firstPosting = null
      _.lastPosting = null
      _.contentDeadline = null
    }
  },
}
</script>
