<template>
    <b-row>
        <b-col cols="12">
            <table-campaign></table-campaign>
        </b-col>
    </b-row>
</template>

<script>
import TableCampaign from '@/views/table/bs-table/TableCampaign.vue'
import {
    BRow, BCol
} from 'bootstrap-vue'

export default {
    name: 'CampaignIndex',
    components: {
        TableCampaign,

        BRow, BCol
    },
    mounted() {
        let _ = this

        if ( _.$route.params.paymentSuccess ) {
            _.$bvToast.toast('Buat Campaign Berhasil', {
                title: "Sukses",
                variant: "success",
                solid: true,
            })
        }
    }
}
</script>